import GetRedisException from "~/exceptions/getRedis.exception";
import SetRedisException from "~/exceptions/setRedis.exception";
import axios, { AxiosResponse } from 'axios';
import { cache } from "webpack";

export class RedisService {
  constructor() {}
  readonly baseUrlStore = process.env.VSF_STORE_URL;

  public async GetRedis(cacheKey: string): Promise<void> {
    try {
        const cachedData = await axios.post(`${this.baseUrlStore}getRedis?key=${cacheKey}`);
        if (cachedData) {
          return JSON.parse(cachedData.data.cachedData);
        }
    } catch (error) {
        throw new GetRedisException(error)

    }
  }

  public async SetRedis(cacheKey: string, data): Promise<void> {
    try {
        await axios.post(`${this.baseUrlStore}setRedis?key=${cacheKey}`, { data: data, ttl: 31536000 });
    } catch (error) {
        throw new SetRedisException(error)

    }
  } 
}